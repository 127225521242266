/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
	Typography,
	Paper,
	TextField,
	makeStyles,
	Select,
	MenuItem,
	Grid,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { setSelectedCurrency, setSatoshiValue, setCurrencyValue } from '../actions/AppActions';

import BitcoinLogo from '../bitcoin-logo.png';

import './Converter.css';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: theme.palette.text.primary,
		maxWidth: '500px',
		maxHeight: '500px',
		margin: '0 0 0 28px',
		backgroundColor: '#000000',
	},
	logo: {
		display: 'inline-block',
		height: '24px',
		width: '24px',
		marginLeft: '4px',
		paddingTop: '2px',
	},
	result: {
		marginTop: '24px',
	},
	menu: {
		color: theme.palette.text.primary,
	},
	output: {
		padding: '18.5px 14px',
		width: '195px',
	},
}));

const SAT = 0.00000001;

const Converter = (props) => {
	const {
		setSelectedCurrency,
		supportedCurrencies,
		selectedCurrency,
		setSatoshiValue,
		satoshiValue,
		setCurrencyValue,
		currencyValue,
	} = props;
	const classes = useStyles();

	const calculateValue = () => {
		const computedVal = (SAT * selectedCurrency.price * satoshiValue)
			.toFixed(3)
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		setCurrencyValue(computedVal);
	};

	const handleChange = (e) => {
		const val = e.target.value;
		if (val !== '') {
			const inputVal = Math.abs(Number(e.target.value));
			if (!isNaN(inputVal)) {
				setSatoshiValue(inputVal);
			}
		} else {
			setSatoshiValue('');
		}
	};

	useEffect(() => {
		calculateValue();
	}, [selectedCurrency]);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Paper className={classes.paper} variant="outlined" square elevation={3}>
					<form noValidate autoComplete="off">
						<Typography
							variant="h5"
							style={{
								display: 'flex',
								alignItems: 'baseline',
							}}
						>
							Amount in Satoshis <img className={classes.logo} alt="btc logo" src={BitcoinLogo} />
						</Typography>
						<br />
						<TextField
							onChange={handleChange}
							type="text"
							id="outlined-basic"
							aria-label="Satoshis"
							variant="outlined"
							placeholder="Enter numeric value here"
							value={satoshiValue}
							onKeyUp={calculateValue}
						/>
					</form>
					<div className={classes.result}>
						<Typography variant="h5" style={{ marginRight: '32px' }}>
							Amount in{' '}
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={selectedCurrency.displayName}
								onChange={(e) => {
									const val = e.target.value;
									const curr = supportedCurrencies.filter((item) => item.displayName === val)[0];
									setSelectedCurrency(curr);
								}}
							>
								{supportedCurrencies.map((item) => (
									<MenuItem className={classes.menu} key={item.val} value={item.displayName}>
										{item.displayName}
									</MenuItem>
								))}
							</Select>
						</Typography>
						<br />
						<Typography className={classes.output}>
							{selectedCurrency.sign}
							&nbsp;
							{currencyValue}
						</Typography>
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		supportedCurrencies: state.supportedCurrencies,
		selectedCurrency: state.selectedCurrency,
		satoshiValue: state.satoshiValue,
		currencyValue: state.currencyValue,
	};
};

const mapDispatchToProps = {
	setSelectedCurrency,
	setSatoshiValue,
	setCurrencyValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Converter);
