/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { getPrices } from './services/Api';
import { Typography } from '@material-ui/core';
import Converter from './containers/Converter';
import { setPrices } from './actions/AppActions';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import useInterval from './useInterval';

import './App.css';

const darkTheme = createMuiTheme({
	palette: {
		type: 'dark',
	},
});

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: '1em',
		backgroundColor: '#161f36',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.primary,
	},
}));

const UPDATE_PRICES_INTERVAL = 5 * 60 * 1000; // 5 mins in ms

const App = (props) => {
	const { setPrices, selectedCurrency } = props;
	const classes = useStyles();

	const updatePrices = async () => {
		const res = await getPrices();
		if (res) {
			setPrices(res);
		}
	};

	useEffect(() => {
		updatePrices();
	}, []);

	// update prices every 10 seconds
	useInterval(() => {
		updatePrices();
	}, UPDATE_PRICES_INTERVAL);

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<div className={classes.root}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography
							variant="h1"
							style={{ margin: '32px', fontSize: '48px', fontWeight: 'bold' }}
						>
							Stacking Sats Satoshi Converter
						</Typography>
						<Typography style={{ margin: '32px', fontWeight: 600 }}>
							Get the latest information about Bitcoin (BTC) price. A simple free currency converter
							from Bitcoin (Satoshi) to {selectedCurrency.longName} and vice versa.
						</Typography>
						<Converter />
						<Typography style={{ margin: '32px', marginTop: '12px', fontWeight: 600 }}>
							Note: 1 Satoshi = 0.00000001 BTC
						</Typography>
					</Grid>
				</Grid>
			</div>
		</ThemeProvider>
	);
};
const mapStateToProps = (state) => {
	return {
		selectedCurrency: state.selectedCurrency,
	};
};

const mapDispatchToProps = {
	setPrices,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
