export const GET_PRICES = 'GET_PRICES';
export const SET_PRICES = 'SET_PRICES';
export const SET_PRICE = 'SET_PRICE';
export const SET_SELECTED_CURRENCY = 'SET_SELECTED_CURRENCY';
export const SET_SATOSHI_VALUE = 'SET_SATOSHI_VALUE';
export const SET_CURRENCY_VALUE = 'SET_CURRENCY_VALUE';

export const getPrices = (data) => {
	return {
		type: GET_PRICES,
		payload: data,
	};
};

export const setPrices = (data) => {
	return {
		type: SET_PRICES,
		payload: data,
	};
};

export const setPrice = (data) => {
	return {
		type: SET_PRICE,
		payload: data,
	};
};

export const setCurrencyValue = (data) => {
	return {
		type: SET_CURRENCY_VALUE,
		payload: data,
	};
};

export const setSelectedCurrency = (data) => {
	return {
		type: SET_SELECTED_CURRENCY,
		payload: data,
	};
};

export const setSatoshiValue = (data) => {
	return {
		type: SET_SATOSHI_VALUE,
		payload: data,
	};
};
