import {
	SET_PRICES,
	SET_SATOSHI_VALUE,
	SET_CURRENCY_VALUE,
	SET_SELECTED_CURRENCY,
} from '../actions/AppActions';

const initialState = {
	supportedCurrencies: [
		{
			val: 'usd',
			sign: '$',
			displayName: 'USD 🇺🇸',
			longName: 'United States Dollar (USD)',
			price: 0,
		},
		{ val: 'eur', sign: '€', displayName: 'EUR 🇪🇺', longName: 'European Euro (EUR)', price: 0 },
		{
			val: 'gbp',
			sign: '£',
			displayName: 'GBP 🇬🇧',
			longName: 'British Pound Sterling (GBP)',
			price: 0,
		},
		{
			val: 'cad',
			sign: 'C$',
			displayName: 'CAD 🇨🇦',
			longName: 'Canadian Dollar (CAD)',
			price: 0,
		},
		{
			val: 'aud',
			sign: 'A$',
			displayName: 'AUD 🇦🇺',
			longName: 'Australian Dollar (AUD)',
			price: 0,
		},
		{ val: 'chf', sign: 'CHF', displayName: 'CHF 🇨🇭', longName: 'Swiss Franc (CHF)', price: 0 },
		{ val: 'jpy', sign: '¥', displayName: 'JPY 🇯🇵', longName: 'Japanese Yen (JPY)', price: 0 },
	],
	selectedCurrency: {
		val: 'usd',
		sign: '$',
		displayName: 'USD 🇺🇸',
		longName: 'United States Dollar (USD)',
		price: '',
	},
	satoshiValue: '',
	currencyValue: '',
};

const appReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_PRICES:
			return {
				...state,
				supportedCurrencies: [
					{ ...state.supportedCurrencies[0], price: payload.USD },
					{ ...state.supportedCurrencies[1], price: payload.EUR },
					{ ...state.supportedCurrencies[2], price: payload.GBP },
					{ ...state.supportedCurrencies[3], price: payload.CAD },
					{ ...state.supportedCurrencies[4], price: payload.AUD },
					{ ...state.supportedCurrencies[5], price: payload.CHF },
					{ ...state.supportedCurrencies[6], price: payload.JPY },
				],
				selectedCurrency: { ...state.supportedCurrencies[0], price: payload.USD },
			};
		case SET_SATOSHI_VALUE:
			return {
				...state,
				satoshiValue: payload,
			};
		case SET_CURRENCY_VALUE:
			return {
				...state,
				currencyValue: payload,
			};
		case SET_SELECTED_CURRENCY:
			return {
				...state,
				selectedCurrency: payload,
			};
		default:
			return state;
	}
};

export default appReducer;
