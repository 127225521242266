const ENDPOINT = 'https://satoshi-converter.app';

export const getPrices = async () => {
	try {
		const url = `${ENDPOINT}/getPrices`;
		let response = await fetch(url, {
			method: 'GET',
			mode: 'cors',
		});
		response = await response.json();
		return response;
	} catch (error) {
		console.error(error);
		return null;
	}
};
